import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./Nav.module.scss";

const Nav = (props) => (
  <nav>
    <ul>
      <li>
        <NavLink to="/" exact>Forside</NavLink>
      </li>
      <li>
        <NavLink to="/priser" exact>Listepriser</NavLink>
      </li>
      <li>
        <a className={classes.disabled}>Support</a>
      </li>
      <li>
        <a className={classes.disabled}>Mød Monosoft</a>
      </li>
    </ul>
  </nav>
);

export default Nav;
