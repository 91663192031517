import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import Title from "../../components/Texts/Title/Title";

import classes from "./Reviews.module.scss";
import Review from "./Review/Review";

class Reviews extends Component {
  state = {
    key: null,
  };

  render() {
    return (
      <section className={classes.reviewsContainer}>
        <Title style={{ textAlign: "center", color: "#F1E3F3", marginBottom: "40px" }}>Søde ord fra kunder</Title>

        {/* Logic to get Cards with cases, and Create a carousel. Get through props and State */}

        <OwlCarousel 
          // allowFullScreen 
          // mergeFit={false} 
          autoWidth 
          center
          stagePadding={40}
          margin={40}
          items={1}
          autoplay
          autoplayHoverPause
          autoplayTimeout={5000}
          loop
        >
          <Review
            person={"Mie & Eivind"}
            client={"Madfærd"}
            quote={
              <i>
                Vi er blevet vildt glade for vores nye hjemmeside! Den sparer os rigtig meget tid, og den har minimeret
                den tid vi skal bruge på manuel abonnentstyring og andre "kedelige" administrative opgaver.
                <br />
                <br/>
                Kæmpe Kudos til Monosoft!
              </i>
            }
          />
          <Review
            person={"Paul Lillelund"}
            client={"Biomassebørsen"}
            quote={
              <i>
                Med MBS har vi fuldt overblik over både processer og ordrebeholdning. Løsningen letter både vores logistik og sørger for at alle vore varer bliver 100% sporbare fra start til slut. MBS blev hurtigt tilpasset vores virkelighed og jeg oplever vores samarbejde med Monosoft som konstruktivt og handlingsorienteret
              </i>
            }
          />
          <Review
            person={"Kai Boisen"}
            client={"Skovdyrkerne"}
            quote={
              <i>
                Vi har valgt MBS til digitalisering af vores dokumentation. Det forenkler vores arbejdsdag, vores data er korrekte og besparelsen på både mandetimer og bearbejdning af data kan ses på bundlinjen.
              </i>
            }
          />
          <Review
            person={"Jette Jensen"}
            client={"Naturstyrelsen"}
            quote={
              <i>
                For os betød MBS en forenkling af vore arbejdsgange. Løsningen letter vores hverdag, fordi vi kan se aktuel status på opgaver og se alle de oplysninger om opgaverne vi har brug for. Vores arbejdsdag er blevet forenklet på flere områder, til glæde for både personale og for økonomien.
              </i>
            }
          />
        </OwlCarousel>
      </section>
    );
  }
}

export default Reviews;
