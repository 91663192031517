import React from "react";
import { Link } from "react-router-dom";
import List from "../List/List";

import classes from "./PreFooter.module.scss";

const PreFooter = (props) => {
  const informationList = [
    {
      key: "priceLink",
      content: <Link to="/priser">Listepriser</Link>,
    },
    // {
    //   key: "supportLink",
    //   content: <a style={{ "text-decoration": "line-through"}}>Support</a>,
    // },
  ];

  return (
    <div className={classes.preFooter}>
      <div className={classes.contentWrapper}>
        <List textIsLight listTitle="Informationer" listItems={informationList} />

        {/* <List textIsLight listTitle="Vores Intiativer" listItems /> */}
      </div>
    </div>
  );
};

export default PreFooter;
