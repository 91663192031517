import React from "react";
import Heading from "../../../components/Texts/Heading/Heading";
import Paragraph from "../../../components/Texts/Paragraph/Paragraph";
import SubHeading from "../../../components/Texts/SubHeading/SubHeading";

import classes from "./Review.module.scss";

const Review = (props) => (
  <div className={classes.Review}>
    <SubHeading style={{ textAlign: "center", fontSize: "16px", color: "#F1E3F3" }}>{props.person}</SubHeading>
    <Heading style={{ textAlign: "center", fontSize: "24px", color: "#F1E3F3" }}>{props.client}</Heading>
    <Paragraph style={{ color: "#F1E3F3" }}>{props.quote}</Paragraph>
  </div>
);

export default Review;
