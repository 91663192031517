import React from "react";
import Aux from "../Auxiliary/Auxiliary";
import Header from "../../components/Header/Header";
import PreFooter from "../../components/PreFooter/PreFooter";
import Footer from "../../components/Footer/Footer";

const Layout = (props) => (
  <Aux>
    <Header />
    <main>
      {props.children}
    </main>
    <PreFooter />
    <Footer />
  </Aux>
);

export default Layout;
