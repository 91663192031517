import React from "react";

import classes from "./HomePage.module.scss";

import Aux from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/UI/Buttons/Button";
import HashButton from "../../components/UI/Buttons/HashButton";
import HeroText from "../../components/Texts/HeroText/HeroText";
import WaveSection from "../../components/WaveSection/WaveSection";
import SubHeading from "../../components/Texts/SubHeading/SubHeading";
import Paragraph from "../../components/Texts/Paragraph/Paragraph";
import Title from "../../components/Texts/Title/Title";
import Heading from "../../components/Texts/Heading/Heading";
import Cases from "../../containers/Cases/Cases";
import Reviews from "../../containers/Reviews/Reviews";
import Contact from "../../components/Contact/Contact";

import lightbulbImg from "../../assets/Idea.png";
import mbsIcons from "../../assets/Images/system_icons.png";

const scrollWithOffset = (el) => {
  console.log(document.documentElement.scrollTop);
  console.log(document.body.scrollTop);
  const yCoordinate = el.getBoundingClientRect().top;
  console.log(yCoordinate);
  window.scrollTo({ top: yCoordinate, behavior: 'smooth' });

}
const OpenNewTab = (href) => {
  console.log(href);
  // window.open(href, "_blank");
}

const HomePage = (props) => (
  <Aux>
    <div className={classes.heroSection}>
      <div className={classes.heroContent}>
        <HeroText
          headingText={"Fleksible løsninger"}
          titleText={"TIL DIN VIRKSOMHED"}
          pText={
            <span style={{ marginBottom: "-20px" }}>
              Vi er udviklingshuset der kan hjælpe dig, ja <b>DIG</b>, med din næste hjemmeside, webshop, logistik
              system eller anden <b>IT løsning</b>.
            </span>
          }
        />

        <div className={classes.buttonsHero}>
          <HashButton type="Primary" to="/#Contact">Slå på tråden</HashButton>
          <HashButton type="Text" to="/#Cases">Se Vores Cases</HashButton>
        </div>
      </div>

      <div className={classes.heroImage}></div>
    </div>

    <WaveSection>
      <div className={classes.ideaImage}>{/* <img src={lightbulbImg} alt="Lightbulb"/> */}</div>
      <div className={classes.waveText}>
        <SubHeading style={{ color: "#F1E3F3" }}>Vores Kompetencer - Din Virksomhed</SubHeading>
        <Paragraph style={{ color: "#F1E3F3", marginTop: "16px" }}>
          <span className="graphics graphics__angleBottomRight graphics__angleBottomRightLightColor">
            Med kunder fra forsynings-, skov- og efterretningsbranchen til hjemmesider der skaber omtale og
            opmærksomhed, breder Vores evner sig over en bred vifte. Med forståelse for Vores kunders behov, udformer vi
            en skræddersyet og optimeret løsning der kan løse dit problem på den mest effektive måde.
          </span>
        </Paragraph>
      </div>
    </WaveSection>

    <article className={classes.mbsSytemSection}>
      <Title style={{ textAlign: "center" }}>
        Ét system <br /> til hele din forretning
      </Title>

      <div className={classes.mbsIconsImg}>
        <img src={mbsIcons} alt="MBS Ikoner" />
      </div>

      <div className={classes.mbsContent}>
        <div className={classes.mbsText}>
          <Heading>Monosoft Business Solution - Dit All-In-One</Heading>
          <Paragraph>
            <span>
              Med MBS får du et fleksibelt værktøj der nemt tilpasses <b>din</b> daglige arbejdsgang.
            </span>
          </Paragraph>
          <Paragraph>
            <span>
              Med <b>MBS</b> og <b>Tradenda</b> har du kamera og skriftlig dokumentation i <b>ét samlet værktøj</b>. Et
              overskueligt værktøj der afvikles på mobil og tablet, så du får <b>alle informationer</b> du har brug for
              i marken - der hvor du <b>har brug for dem</b>.
            </span>
          </Paragraph>
          <Paragraph>
            <span>
              Medarbejdere på kontoret modtager data fra marken <b>med det samme</b>, så kommunikation går nemt og
              flydende.
            </span>
          </Paragraph>

          <div className={classes.mbsButton}>
            <Button type="Primary">Vores Værktøjer</Button>
          </div>
        </div>
        <div className={classes.collegueImage}></div>
      </div>
      <div className={classes.graphicsHolder}></div>
    </article>

    <Cases />

    <Reviews />

    <Contact />
  </Aux>
);

export default HomePage;
