import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import Title from "../../components/Texts/Title/Title";
import Card from "../../components/UI/Card/Card";

import nstImg from "../../assets/nstlogo.jpg";
import madfaerdImg from "../../assets/Madfaerd.png";
import viddjursImg from "../../assets/videndjursLogo.jpg";

import classes from "./Cases.module.scss";

class Cases extends Component {
  render() {
    
    return (
      <section id="Cases" className={classes.casesContainer}>
        <Title style={{ textAlign: "center" }}>Cases</Title>

        <OwlCarousel
          center
          allowFullScreen
          mergeFit={true}
          autoWidth
          stagePadding={80}
          margin={40}
          items={1}
          loop
        >
          <Card
            img={nstImg}
            heading="Naturstyrelsens Brændebutik"
            href="https://braendebutik.nst.dk/forside"
            text="Naturstyrelsen ønskede en nem og overskuelig måde, at håndtere og sælge træ fra Danmarks mange skove. Med hjælp fra Monosofts MBS system kunne dette lade sig gøre yndefuldt."
          />
          <Card
            img={madfaerdImg}
            heading="Madfærd"
            href="https://madfaerd.org/"
            text="Madfærd leverer sund, varieret og spændende mad til skolebørn i Ry.
            Med et nyt system bygget fra bunden, er deres hverdag blevet nemmere og mere strømlinet."
          />
          <Card
            img={viddjursImg}
            heading="Viden Djurs"
            href="https://www.videndjurs.dk/"
            text="Med en strålende samarbejde mellem Viden Djurs og Monosoft, tilbyder vi vores evner til vikariere. Samtidigt byder Monosoft praktikanter velkommen til spændende opgaver. "
          />
        </OwlCarousel>
      </section>
    );
  }
}

export default Cases;
