import React from "react";

import Heading from "../Texts/Heading/Heading";
import SubHeading from "../Texts/SubHeading/SubHeading";

import classes from "./Contact.module.scss";

const Contact = (props) => (
  <section id="Contact" className={classes.contact}>
    <div className={classes.image}>{/* image */}</div>
    <div className={classes.text}>
      <Heading style={{ marginBottom: "16px" }}>Har du en ide der skal sættes i bevægelse?</Heading>
      <SubHeading style={{ marginBottom: "40px" }}>Vi sørger for kaffen.</SubHeading>

      <div className={classes.links}>
        <SubHeading>
          <a className={classes.contactLink} href="mailto:info@monosoft.dk">
            info@monosoft.dk
          </a>
        </SubHeading>
        <SubHeading>
          <a className={classes.contactLink} href="tel:71969796">
            +45 7196 9796
          </a>
        </SubHeading>
      </div>
    </div>
  </section>
);

export default Contact;
