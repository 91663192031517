import React from "react";
import SubHeading from "../Texts/SubHeading/SubHeading";

import classes from "./List.module.scss";

const List = (props) => {
  let listItems;

  if (props.listItems.length > 0) {
    listItems = props.listItems.map((item) => (
      <li key={item.key} className={classes.listItem}>
        {item.content}
      </li>
    ));
  }

  return (
    <div className={classes.listContainer}>
      <SubHeading
        style={{ color: props.textIsLight ? "#F1E3F3" : null, textDecoration: !listItems ? "line-through" : null }}
      >
        {props.listTitle}
      </SubHeading>

      <ul className={[classes.list, props.textIsLight ? classes.list_lightText : null].join(" ")}>
        {listItems ? listItems : "* Under konstruktion"}
      </ul>
    </div>
  );
};

export default List;
