import React from "react";
import Heading from "../../components/Texts/Heading/Heading";
import SubHeading from "../../components/Texts/SubHeading/SubHeading";
import Paragraph from "../../components/Texts/Paragraph/Paragraph";
import Title from "../../components/Texts/Title/Title";
import Aux from "../../hoc/Auxiliary/Auxiliary";

import classes from "./Prices.module.scss";
import HeroText from "../../components/Texts/HeroText/HeroText";

const Prices = (props) => {
  const widthStyle = { maxWidth: "960px" };

  return (
    <article className={classes.articleContainer}>
      {/* <span className="graphics graphics__angleTopLeft graphics__angleBottomRight">
        <Title style={widthStyle}>LISTEPRISER</Title>
      </span> */}

      <div className={classes.textContainer}>
        <HeroText titleText={<span style={{ transform: "translateX(-40px)" }}> LISTEPRISER </span>} />
      </div>

      <section className={classes.priceCardsContainer}>
        <div className={classes.priceCard}>
          <div className={classes.priceCardSection}>
            <h2>Ad'Hoc</h2>
            <b>312,5 kr.</b>
            <p>pr. påbegyndt kvarter</p>
          </div>

          <div className={classes.priceCardSection}>
            <h2>HASTER</h2>
            <b>468,75 kr.</b>
            <p>pr. påbegyndt kvarter</p>
          </div>

          <div className={classes.priceCardSection}>
            <h2>WEEKEND- & AFTENTILLÆG</h2>
            <b>50% tillæg</b>
            <p>jævfør priser ovenfor</p>
          </div>
        </div>

        <div className={classes.priceCard}>
          <div className={classes.priceCardSection}>
            <h2>Planlagt Opgave</h2>
            <b>750,- kr.</b>
            <p>pr. påbegyndt time</p>
          </div>

          <div className={classes.priceCardSection}>
            <h2>HASTER</h2>
            <b>1125,- kr.</b>
            <p>pr. påbegyndt time</p>
          </div>

          <div className={classes.priceCardSection}>
            <h2>WEEKEND- & AFTENTILLÆG</h2>
            <b>50% tillæg</b>
            <p>jævfør priser ovenfor - IKKE haster</p>
          </div>
        </div>
      </section>

      <div className={classes.textContainer}>
        <SubHeading style={widthStyle}>Hurtigt og godt</SubHeading>
        <Paragraph>
          <b>Ad'Hoc HASTER - </b> dette er hvis du ringer til Monosoft, for at modtage support, da du, og dit problem,
          bliver stillet forrest i køen.
        </Paragraph>

        <Paragraph>
          <b>Planlagt Opgave - </b> opgaver eller support der er kommet ind via support@monosoft.dk og eller planlagt
          ved et møde med kunden.
        </Paragraph>

        <Paragraph>
          <b>Ad'Hoc - </b>som udgangspunkt, alle opgaver der tager under én time, eller som er igangsat af en kundens
          opkald til vores supporttelefon.
        </Paragraph>

        <Paragraph>
          <b>Planlagt Opgave HASTER -</b> opgaver eller support der er kommet ind via support@monosoft.dk hvori
          emnefeltet er udfyldt med "HASTER", vil prioriteres højere, og dermed påbegyndes opgaven indenfor én
          arbejdsdag.
        </Paragraph>
        <Paragraph>
          <b>Planlagt Opgave HASTER m. weekend-/aftentillæg - </b>sendes en mail til support@monosoft.dk og emnefeltet
          udfyldes med "AKUT", herved vil opgaven påbegyndes så snart opgaven er modtaget, og arbejdes på indtil opgaven
          er løst - herved er du som kunde også indforstået med eventuelle aftentillæg.
        </Paragraph>
      </div>

      <div className={classes.textContainer}>
        <SubHeading>Klippekort</SubHeading>
        <Paragraph>
          Vi tilbyder dig som kunde et klippekort. Herved forudbetales hjælp og support for en varighed af 12 måneder.
          Vi tilbyder følgende klipperkort:
        </Paragraph>
      </div>

      <div className={classes.clipCard}>
        <SubHeading style={{ color: "#F1E3F3", textAlign: "center", marginBottom: "16px" }}>Klippekort</SubHeading>

        <div className={classes.clipPrice}>
          <p>200 timer</p>
          <b>140.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>300 timer</p>
          <b>195.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>400 timer</p>
          <b>240.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>600 timer</p>
          <b>345.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>800 timer</p>
          <b>440.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>1000 timer</p>
          <b>525.000,-</b>
        </div>

        <div className={classes.clipPrice}>
          <p>1200 timer</p>
          <b>600.000,-</b>
        </div>
      </div>

      <div className={classes.textContainer}>
        <Paragraph>
          Vi tilbyder også klippekort på mere end 1200 timer. Disse klippekort vil være til en pris af 500,- pr. time
        </Paragraph>
      </div>

      <div className={classes.textContainer}>
        <SubHeading>Fastpris og aftaler</SubHeading>
        <Paragraph>Du kan som kunde forhandle en fastpris på software leveret af Monosoft. </Paragraph>
        <Paragraph>
          Hvis du er kunde på MBS eller appen Tradenda, vil fejl i disse programmer løses uden beregning, da fejlen
          ligger ved Monosoft.
        </Paragraph>
      </div>
    </article>
  );
};

export default Prices;
