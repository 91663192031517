import React from "react";

import Heading from "../Heading/Heading";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";

import classes from "./HeroText.module.scss";

const HeroText = (props) => (
  <article style={{ ...props.style }} className={classes.HeroText}>
    <Heading>
      <span className="graphics graphics__angleTopLeft">{props.headingText}</span>
    </Heading>

    <Title>{props.titleText}</Title>
    <Paragraph style={{marginTop: 0}}>
      <span className="graphics graphics__angleBottomRight">{props.pText}</span>
    </Paragraph>
  </article>
);

export default HeroText;
