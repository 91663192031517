import React from "react";
import Title from "../Texts/Title/Title";

import classes from "./Footer.module.scss";

const Footer = (props) => {
  const nowDate = new Date();
  const currentYear = nowDate.getFullYear();

  return (
    <footer className={classes.Footer}>
      <div className={classes.contentWrapper}>
        <Title style={{ color: "#F1E3F3", marginBottom: "24px", fontSize: "24px" }}>
          &copy; Monosoft {currentYear}
        </Title>
        <a className={classes.contactLink} href="mailto:info@monosoft.dk">
          info@monosoft.dk
        </a>
        <a className={classes.contactLink} href="tel:71969796">
          +45 7196 9796
        </a>
      </div>
    </footer>
  );
};

export default Footer;
