import React from 'react';

import classes from "./Button.module.scss"

const Button = (props) => {

  // const appliedClasses = [ classes.Button, classes. ]
  
  return(
  <button
    onClick={props.click}
    className={[classes.Button, classes[props.type]].join(" ")}
    disabled={props.disabled}
    style={{...props.style}}
  >
    {props.children}
  </button>
)}

export default Button