import React from 'react';

import classes from "./WaveSection.module.scss"

const WaveSection = (props) => (
  
  <div style={{...props.style}} className={classes.WaveSection}>
    {props.children}
  </div>
  
)

export default WaveSection