import React from "react";

import classes from "./Paragraph.module.scss";

const Paragraph = (props) => (
  <p style={{ ...props.style }} className={classes.Paragraph}>
    {props.children}
  </p>
);

export default Paragraph;
