import React from 'react';
import { HashLink } from 'react-router-hash-link';

import classes from "./Button.module.scss"

const HashButton = (props) => {

  // const appliedClasses = [ classes.Button, classes. ]
  
  return(
  <HashLink
    className={[classes.Button, classes[props.type]].join(" ")}
    smooth
    to={props.to}
    disabled={props.disabled}
    style={{...props.style}}
  >
    {props.children}
  </HashLink>
)}

export default HashButton