import React from 'react';
import { NavLink } from "react-router-dom";
import Logo from "../../assets/MonosoftLogo/1x/logo_monosoftBlue.png"
import Nav from '../Nav/Nav';

import classes from "./Header.module.scss";

const Header = (props) => (
  <>
    <div className={classes.newsRow} >
      <h4>
        Monosoft er pr. 1. januar 2021 blevet opkøbt af Complea A/S. Vi ser frem til fortsat at servicere vores nuværende kunder under navnet Complea, med mulighed for at kunne tilbyde endnu flere løsninger. Vi henviser til mere information
        <a target="_blank" href="https://www.complea.dk/viden/it-komet-opkoeber-igen">HER</a>
      </h4>
    </div>
    <header className={classes.Header}>
      <NavLink className={classes.Logo} to="/" exact>
        <img src={Logo} alt="Monosofts logo" />
      </NavLink>
      <Nav />
    </header>
  </>
)

export default Header