import React from "react";

import Heading from "../../Texts/Heading/Heading";
import Paragraph from "../../Texts/Paragraph/Paragraph";
import Button from "../Buttons/Button";

import classes from "./Card.module.scss";
const OpenNewTab = (href) => {
  console.log(href);
  // window.open(href, "_blank");
}

const Card = (props) => {
  return (
    <div className={classes.Card}>
      <div
        className={classes.imgContainer}
        style={{
          backgroundImage: `url(${props.img})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className={classes.Text}>
        <Heading>{props.heading}</Heading>
        <Paragraph>{props.text}</Paragraph>
      </div>
      <div className={classes.buttons}>
        {/* <Button onclick={OpenNewTab(props.href)} type="Text">BESØG SIDEN</Button> */}
      </div>
    </div>
  );
};

export default Card;
