import React from "react";
import "./App.scss";

import { Route, Switch, Redirect } from "react-router-dom" 

import Layout from "./hoc/Layout/Layout";
import HomePage from "./screens/HomePage/HomePage";
import Prices from "./screens/Prices/Prices";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

function App() {
  return (
    <div className="App">
      <Layout>
          <ScrollToTop />
          <Switch>

            {/* <Route path="/meet" component={Meet}/> */}
            <Route path="/priser" component={Prices} />
            <Route path="/" component={HomePage} />

            <Redirect from="/forside" to="/" />
            <Redirect from="/home" to="/" />
            <Redirect from="/hjem" to="/" />
          </Switch>
      </Layout>
    </div>
  );
}

export default App;
